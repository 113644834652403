import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="shadow bg-gray-900 fixed bottom-0 w-full me-2">
        <div className="w-full max-w-screen-xl mx-auto p-2">
          <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © {currentYear}{" "}
            <Link to="/" className="hover:underline">
              FreePlayGame™
            </Link>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </>
  );
}
