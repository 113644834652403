import React from "react";
import { Link } from "react-router-dom";

export default function TermCondition() {
  const appName = "Free Play Game";
  const appDomain = "www.freeplaygame.in";
  const sections = [
    {
      title: "License to Use the Website",
      content: [
        `Unless otherwise stated, ${appName} and/or its licensors own the intellectual property rights published on this website and materials used on ${appName}. Subject to the license below, all these intellectual property rights are reserved.`,
        `You may view, download for caching purposes only, and print pages, files, or other content from the website for your own personal use, subject to the restrictions set out below and elsewhere in these terms and conditions.`,
        "You must not:",
        <ul className="list-decimal mx-5">
          <li>
            Republish material from{" "}
            <Link to="/" className="text-blue-500">
              {appDomain}
            </Link>
          </li>
          <li>
            Sell, rent, or sub-license material from{" "}
            <Link to="/" className="text-blue-500">
              {appDomain}
            </Link>
          </li>
          <li>
            Reproduce, duplicate, or copy material from{" "}
            <Link to="/" className="text-blue-500">
              {appDomain}
            </Link>
          </li>
          <li>
            Redistribute content from{" "}
            <Link to="/" className="text-blue-500">
              {appDomain}
            </Link>
            (unless the content is specifically made for redistribution).
          </li>
        </ul>,
      ],
    },
    {
      title: "Acceptable Use",
      content: [
        `You must not use this website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of ${appName} or in any way which is unlawful, illegal, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.`,
        `You must not use this website to copy, store, host, transmit, send, use, publish, or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit, or other malicious computer software.`,
        `You must not conduct any systematic or automated data collection activities on or in relation to this website without ${appName}'s express written consent.`,
      ],
    },
    {
      title: "Restricted Access",
      content: `If you create an account on the website, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password.`,
    },
    {
      title: "Submissions",
      content: `You grant ${appName} a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your comments in any and all forms, formats or media.`,
    },
    {
      title: "Disclaimer of Warranties",
      content: `This website is provided "as is," with all faults, and ${appName} makes no express or implied representations or warranties, of any kind related to this website or the materials contained on this website. Additionally, nothing contained on this website shall be construed as providing consult or advice to you.`,
    },
    {
      title: "Limitation of Liability",
      content: `In no event shall ${appName}, nor any of its officers, directors and employees, be held liable for anything arising out of or in any way connected with your use of this website whether such liability is under contract. ${appName}, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website`,
    },
  ];

  return (
    <div className="mb-6 text-lg my-3">
      <h1 className="text-4xl font-semibold text-center mb-5">
        Terms & Conditions
      </h1>
      <p className="my-2">
        Welcome to <b>{appName}</b>. These terms and conditions outline the
        rules and regulations for the use of our website.
      </p>
      <p className="my-2">
        By accessing this website{" "}
        <Link to="/" className="text-blue-500">
          {appDomain}
        </Link>
        , we assume you accept these terms and conditions in full. Do not
        continue to use {appName}'s website if you do not accept all of the
        terms and conditions stated on this page.
      </p>

      {sections.map((section, index) => (
        <section key={index} className="my-4">
          <h5 className="text-3xl font-semibold">{section.title}</h5>
          {Array.isArray(section.content) ? (
            section.content.map((text, idx) => (
              <div key={idx} className="my-2">
                {text}
              </div>
            ))
          ) : (
            <p className="my-2">{section.content}</p>
          )}
        </section>
      ))}
    </div>
  );
}
