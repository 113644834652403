import React, { useEffect, useState, lazy, Suspense } from "react";
import AllGamesData from "../Asset/game.json";

const GameCard = lazy(() => import("./GameCard"));

export default function Home() {
  const [visibleGames, setVisibleGames] = useState(18); // Number of games to show
  const [gamesToShow, setGamesToShow] = useState(
    AllGamesData.slice(0, visibleGames)
  ); // Initial games to show

  // Load more games when scrolling to the bottom
  const loadMoreGames = () => {
    if (visibleGames < AllGamesData.length) {
      setVisibleGames((prev) => prev + 18); // Increase the number of visible games
    }
  };

  useEffect(() => {
    setGamesToShow(AllGamesData.slice(0, visibleGames)); // Update games to show based on visibleGames
  }, [visibleGames]);

  // Intersection Observer setup
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMoreGames();
      }
    });

    const target = document.getElementById("load-more-trigger");
    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []);

  return (
    <>
      <div className="grid gap-4 grid-cols-2 md:grid-cols-4 2xl:grid-cols-6 mb-12">
        {gamesToShow.map((game) => (
          <Suspense key={game.slug} fallback={<div>Loading...</div>}>
            <GameCard gameData={game} />
          </Suspense>
        ))}
      </div>
      {/* Trigger for loading more games */}
      <div
        id="load-more-trigger"
        style={{ height: "20px", margin: "20px" }}
      ></div>
    </>
  );
}
