import React from "react";
import { Link } from "react-router-dom";
import Banner from "../Asset/navfreeplay.png";

export default function Navbar() {
  return (
    <>
      <nav className="fixed top-0 z-50 w-full bg-gray-800 dark:border-gray-700 mb-5">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <Link to="/" className="flex ms-2 md:me-24">
                <img src={Banner} className="h-8 me-3" alt="Logo" />
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
