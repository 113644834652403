import React from "react";

export default function About() {
  const appName = "Free Play Game";
  return (
    <div className="mb-6 sm:justify-between text-lg m-3">
      <h1 className="text-4xl font-semibold text-center mb-5">About Us</h1>
      <p className="my-2">
        Welcome to <b>{appName}</b>, your ultimate destination for free online
        gaming!
      </p>

      <section className="my-4">
        <h6 className="text-2xl font-semibold">Who We Are</h6>
        <p className="my-2">
          At <b>{appName}</b>, we are passionate gamers dedicated to bringing
          you the best free-to-play games. Our mission is to provide a platform
          where players of all ages can enjoy a diverse collection of games
          without any cost. Whether you're a casual gamer or a hardcore
          enthusiast, we've got something for everyone.
        </p>
      </section>

      <section className="my-4">
        <h6 className="text-2xl font-semibold">Our Vision</h6>
        <p className="my-2">
          Our vision is to create a vibrant and inclusive gaming community. We
          believe that games should be accessible to everyone, and that's why we
          focus on offering a wide range of free games that cater to different
          tastes and preferences. From action-packed adventures to brain-teasing
          puzzles, our library is constantly growing to ensure there's always
          something new to discover.
        </p>
      </section>

      <section className="my-4">
        <h6 className="text-2xl font-semibold">What We Offer</h6>
        <ul className="list-disc ml-5 space-y-2">
          <li>
            <b>A Variety of Games:</b> Explore our extensive library of games
            across various genres, including action, strategy, puzzle, and more.
          </li>
          <li>
            <b>No Hidden Costs:</b> All our games are completely free to play.
            No subscriptions, no in-game purchases, just pure gaming fun.
          </li>
          <li>
            <b>User-Friendly Experience:</b> Our website is designed with you in
            mind. Enjoy seamless navigation and an easy-to-use interface.
          </li>
          <li>
            <b>Community Engagement:</b> Join our thriving community of gamers.
            Share tips, discuss strategies, and make new friends.
          </li>
        </ul>
      </section>

      <section className="my-4">
        <h6 className="text-2xl font-semibold">Join Us</h6>
        <p className="my-2">
          Join <b>{appName}</b> today and dive into a world of free gaming fun.
          Whether you're looking to unwind after a long day or challenge
          yourself with a new game, <b>{appName}</b> is here to provide endless
          entertainment.
        </p>
        <p>Thank you for being a part of our community. Happy gaming!</p>
      </section>
    </div>
  );
}
