import { Route, Routes } from "react-router-dom";
import Home from "./Component/Home";
import Navbar from "./Component/Navbar";
import Footer from "./Component/Footer";
import Single from "./Component/Single";
import About from "./Component/About";
import TermCondition from "./Component/TermCondition";
import PrivacyPolicy from "./Component/PrivacyPolicy";

function App() {
  return (
    <>
      <Navbar />
      <div className="mt-16 mx-4 mb-12">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/game" element={<Single />} />
          <Route path="/about" element={<About />} />
          <Route path="/term-condition" element={<TermCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
