import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";

export default function GameCard({ gameData, className = "" }) {
  const [loading, setLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 600);

    return () => clearTimeout(timer);
  }, []);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
      <div
        className={`group relative bg-gray-100 shadow-gray-500 rounded-lg overflow-hidden shadow-lg ${className}`}
      >
        <Link to={`game?slug=${gameData.slug}`}>
          {loading && imageLoaded ? (
            <>
              <div
                className={`group relative bg-gray-100 shadow-gray-500 rounded-lg overflow-hidden shadow-lg min-h-[190px] ${className}`}
              >
                <Skeleton />
              </div>
            </>
          ) : (
            <div>
              <img
                className={`w-full h-full object-cover transition-opacity duration-300 ease-in-out`}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                src={gameData.logo}
                alt={gameData.title}
                onLoad={handleImageLoad}
                loading="lazy"
              />
            </div>
          )}
          <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-70 transition-opacity duration-300 ease-in-out"></div>
          <div className="p-4 absolute bottom-0 w-full text-white transform translate-y-full group-hover:translate-y-0 transition-transform duration-300 ease-in-out">
            <h5 className="text-sm sm:text-xl font-bold">{gameData.title}</h5>
          </div>
        </Link>
      </div>
    </>
  );
}
