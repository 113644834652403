import React from "react";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
  const appEmail = "support@freeplaygame.com";
  const appName = "Free Play Game";
  const appDomain = "www.freeplaygame.in";
  return (
    <div className="mb-6 text-lg m-3">
      <h1 className="text-4xl font-semibold text-center mb-5">
        Privacy Policy
      </h1>

      <section className="my-4">
        <h5 className="text-3xl font-semibold">Introduction</h5>
        <p className="my-2">
          Welcome to <b>{appName}</b>. We value your privacy and are committed
          to protecting your personal information. This Privacy Policy explains
          how we collect, use, disclose, and safeguard your information when you
          visit our website{" "}
          <Link to="/" className="text-blue-500">
            {appDomain}
          </Link>
          .
        </p>
      </section>

      <section className="my-4">
        <h5 className="text-3xl font-semibold">Information We Collect</h5>
        <p className="my-2">
          We may collect information about you in a variety of ways. The
          information we may collect on the Site includes:
        </p>

        {[
          {
            title: "Personal Data",
            content:
              "Personally identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Site or when you choose to participate in various activities related to the Site, such as online chat and message boards.",
          },
          {
            title: "Derivative Data",
            content:
              "Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site.",
          },
          {
            title: "Financial Data",
            content:
              "Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Site.",
          },
          {
            title: "Mobile Device Data",
            content:
              "Device information, such as your mobile device ID, model, and manufacturer, and information about the location of your device, if you access the Site from a mobile device.",
          },
          {
            title: "Third-Party Data",
            content:
              "Information from third parties, such as personal information or network friends, if you connect your account to the third party and grant the Site permission to access this information.",
          },
        ].map((item, index) => (
          <div key={index} className="my-3">
            <h6 className="text-xl font-semibold">{item.title}</h6>
            <p className="my-2">{item.content}</p>
          </div>
        ))}
      </section>

      <section className="my-4">
        <h5 className="text-3xl font-semibold">Use of Your Information</h5>
        <p className="my-2">
          Having accurate information about you permits us to provide you with a
          smooth, efficient, and customized experience. Specifically, we may use
          information collected about you via the Site to:
        </p>
        <ul className="list-disc mx-5 space-y-2">
          {[
            "Create and manage your account.",
            "Process your transactions.",
            "Email you regarding your account or order.",
            "Respond to product and customer service requests.",
            "Provide you with tailored advertisements, content, and information.",
            "Improve our services and website.",
            "Monitor and analyze usage and trends to improve your experience with the Site.",
            "Notify you of updates to the Site.",
            "Perform other business activities as needed.",
          ].map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </section>

      <section className="my-4">
        <h5 className="text-3xl font-semibold">
          Disclosure of Your Information
        </h5>
        <p className="my-2">
          We may share information we have collected about you in certain
          situations. Your information may be disclosed as follows:
        </p>

        {[
          {
            title: "By Law or to Protect Rights",
            content:
              "If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.",
          },
          {
            title: "Third-Party Service Providers",
            content:
              "We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.",
          },
          {
            title: "Affiliates",
            content:
              "We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy.",
          },
          {
            title: "Business Partners",
            content:
              "We may share your information with our business partners to offer you certain products, services, or promotions.",
          },
        ].map((item, index) => (
          <div key={index} className="my-3">
            <h6 className="text-xl font-semibold">{item.title}</h6>
            <p className="my-2">{item.content}</p>
          </div>
        ))}
      </section>

      <section className="my-4">
        <h5 className="text-3xl font-semibold">Security of Your Information</h5>
        <p className="my-2">
          We use administrative, technical, and physical security measures to
          help protect your personal information. While we have taken reasonable
          steps to secure the personal information you provide to us, please be
          aware that despite our efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other type of misuse.
        </p>
      </section>

      <section className="my-4">
        <h5 className="text-3xl font-semibold">Policy for Children</h5>
        <p className="my-2">
          We do not knowingly solicit information from or market to children
          under the age of 13. If we learn that we have collected personal
          information from a child under age 13 without verification of parental
          consent, we will delete that information as quickly as possible.
        </p>
      </section>

      <section className="my-4">
        <h5 className="text-3xl font-semibold">
          Changes to This Privacy Policy
        </h5>
        <p className="my-2">
          We may update this Privacy Policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal, or regulatory reasons. We will notify you of any
          changes by updating the "Last Updated" date at the bottom of this
          Privacy Policy. You are encouraged to periodically review this Privacy
          Policy to stay informed of updates.
        </p>
      </section>

      <section className="my-4">
        <h5 className="text-3xl font-semibold">Contact Us</h5>
        <p className="my-2">
          If you have questions or comments about this Privacy Policy, please
          contact us at:
        </p>
        <p className="my-2">
          Email:{" "}
          <a href={`mailto:${appEmail}`} className="text-blue-500">
            {appEmail}
          </a>
        </p>
      </section>
    </div>
  );
}
